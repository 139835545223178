import {
  Container,
  Content,
  MainHeader,
  MainFooter,
} from './InternalLayout.styles';
import { Grid } from 'react-styled-flexboxgrid';
import { Props } from '../Layout.types';

import Footer from '../../composites/Footer';
import Modal from '../../composites/Modal';
import Navigation from '../../composites/Navigation';
import useDeviceStatus from '../../hooks/useDeviceStatus';

const InternalLayout: React.FC<Props> = ({ children }) => {
  useDeviceStatus();

  return (
    <Container>
      <Modal />
      <MainHeader>
        <Grid>
          <Navigation />
        </Grid>
      </MainHeader>
      <Content>
        <Grid>{children}</Grid>
      </Content>
      <MainFooter>
        <Footer />
      </MainFooter>
    </Container>
  );
};

export default InternalLayout;
