/* eslint-disable @typescript-eslint/no-explicit-any */
export enum PreviewTypes {
  'ADD_AGE' = 'ADD_AGE',
  'ADD_COLOR' = 'ADD_COLOR',
  'ADD_IP' = 'ADD_IP',
  'ADD_LAYOUT' = 'ADD_LAYOUT',
  'ADD_NAME' = 'ADD_NAME',
  'ADD_OPTION' = 'ADD_OPTION',
  'ADD_PRICE' = 'ADD_PRICE',
  'ADD_PRODUCT' = 'ADD_PRODUCT',
  'ADD_SIZE' = 'ADD_SIZE',
  'ADD_SKU' = 'ADD_SKU',
  'ADD_FONT_COLOR' = 'ADD_FONT_COLOR',
  'ADD_DATE' = 'ADD_DATE',
  'RESET_PREVIEW' = 'RESET_PREVIEW',
}

export interface Detail {
  id: string;
  name?: string;
}

export interface Color {
  id: string;
  image?: string;
}
export interface PreviewState {
  age?: number;
  color?: Color;
  ip?: Detail;
  layout: string;
  name: string;
  option: string;
  price: number;
  product?: Detail;
  size: string;
  sku: string;
  customFontColor?: string;
  customDate?: Date;
}

export type PreviewAction = {
  payload: any;
  type: PreviewTypes;
  value?: number;
};
