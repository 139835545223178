import { PrivateRouteProps } from './PrivateRoute.types';
import { Redirect } from 'react-router-dom';

import InternalLayout from '../../layout/InternalLayout';

const PrivateRoute: React.FC<PrivateRouteProps> = ({
  children,
  isAuthenticated,
}) =>
  isAuthenticated ? (
    <InternalLayout>{children}</InternalLayout>
  ) : (
    <Redirect to="/" />
  );

export default PrivateRoute;
