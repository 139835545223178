import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  background: ${({ theme }): string => theme.colors.orange};
  display: flex;
  height: 100%;
`;

export const Content = styled.div`
  position: relative;
`;

export const Img = styled.img`
  position: absolute;
  right: 0px;
  top: -15px;
`;
