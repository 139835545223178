import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;

export const Logo = styled.img`
  height: 90px;
  z-index: 3;
`;
