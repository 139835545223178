import { IAction, IState } from './context.types';

export const loginAction = (payload: IState): IAction => ({
  type: 'LOGIN',
  payload,
});

export const changeLanguageAction = (payload: string): IAction => ({
  type: 'CHANGE_LANGUAGE',
  payload,
});

export const logoutAction = (): IAction => ({
  type: 'LOGOUT',
});
