import { Container } from './Loader.styles';
import { LoaderProps } from './Loader.types';

import LoaderAnimation from '../../assets/looties/loader.json';
import Lottie from 'react-lottie';

const Loader: React.FC<LoaderProps> = ({ internalLoader }) => {
  const options = {
    loop: true,
    autoplay: true,
    animationData: LoaderAnimation,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Container internalLoader={internalLoader}>
      <Lottie options={options} height={400} width={400} />
    </Container>
  );
};

export default Loader;
