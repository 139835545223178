/* eslint-disable @typescript-eslint/no-explicit-any */
export enum CartTypes {
  'ADD_TO_CART' = 'ADD_TO_CART',
  'REMOVE_FROM_CART' = 'REMOVE_FROM_CART',
  'ADD_CLIENT_NAME' = 'ADD_CLIENT_NAME',
  'RESET_CART' = 'RESET_CART',
}

export interface CartItem {
  id?: string;
  name: string;
  product: string;
  ip: string;
  size: string;
  price: number;
  color: string;
  image: string;
  layout: string;
  age?: number;
  customFontColor?: string;
  customDate?: string;
}

export interface CartState {
  id?: string;
  name?: string;
  items: CartItem[];
}

export type CartAction = {
  type: CartTypes;
  payload: any;
};
