import styled from 'styled-components';

export const Container = styled.div<{ isVisible?: boolean }>`
  align-items: center;
  background: rgba(0, 0, 0, 0.85);
  display: ${({ isVisible }): string => (isVisible ? 'flex' : 'none')};
  height: 100vh;
  justify-content: center;
  position: absolute;
  width: 100vw;
  z-index: 9;
`;

export const Content = styled.div`
  animation: ${({ theme }) => theme.keyframes.fadeIn} 1s
    cubic-bezier(0.55, 0.055, 0.675, 0.19);
  background: ${({ theme }): string => theme.colors.white};
  border-radius: 0.5rem;
  box-shadow: ${({ theme }): string => theme.shadows.md};
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
  height: 300px;
  justify-content: center;
  padding: 2rem;
  position: relative;
  width: 500px;
`;

export const Input = styled.input`
  border-radius: 4px;
  border: 1px solid ${({ theme }): string => theme.colors.lightGray};
  color: ${({ theme }): string => theme.colors.gray};
  margin-bottom: 5px;
  padding: 1rem;
  width: 100%;
`;

export const Img = styled.img`
  height: 2rem;
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 2rem;
`;

export const Label = styled.label`
  margin-bottom: 1rem;
`;

export const AmountContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0 1rem;
`;

export const Amount = styled.div`
  display: flex;
  font-size: 2rem;
  justify-content: center;
`;

export const Small = styled.span`
  font-size: 1rem;
  padding: 0 0.15rem;
`;

export const Total = styled.div``;
