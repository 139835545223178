export enum IPTypes {
  'ADD_LAYOUTS' = 'ADD_LAYOUTS',
}

export interface Layout {
  id: string;
  name: string;
  image: string;
}

export interface IPState {
  id: string;
  name: string;
  image: string;
  layouts?: Layout[];
}

export type IPAction = {
  type: IPTypes;
  payload: IPState;
};
