const breakpoints = {
  xlg: 1440,
  lg: 1024,
  md: 768,
  sm: 375,
  xs: 0,
};

export type Breakpoints = typeof breakpoints;
export default breakpoints;
