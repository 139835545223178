const colors = {
  black: '#333333',
  brown: '#69615F',
  gray: '#666666',
  lightGray: '#C0BFBC',
  green: '#4AAE35',
  orange: '#FF6700',
  pink: '#E94C91',
  purple: '#564C91',
  white: '#ffffff',
  mint: '#B1EAA3',
};

export type Colors = typeof colors;
export type Color = keyof typeof colors;
export default colors;
