import { createContext, useReducer } from 'react';
import { IState, IAction, IContext } from './context.types';

const defaultState = {
  currency: window.localStorage.getItem('currency') || 'mxn',
  device: window.localStorage.getItem('device') || 'false',
  isAuthenticated:
    window.localStorage.getItem('isAuthenticated') === 'true' &&
    window.localStorage.getItem('token') &&
    window.localStorage.getItem('device')
      ? 'true'
      : 'false',
  language:
    window.localStorage.getItem('language') ||
    window.localStorage.getItem('i18nextLng') ||
    'en',
  token: window.localStorage.getItem('token') || 'false',
};

const reducer = (state: IState, action: IAction): IState => {
  switch (action.type) {
    case 'CHANGE_CURRENCY':
      window.localStorage.setItem('currency', action.payload);
      return {
        ...state,
        currency: action.payload,
      };
    case 'CHANGE_LANGUAGE':
      window.localStorage.setItem('i18nextLng', action.payload);
      return {
        ...state,
        language: action.payload,
      };
    case 'LOGOUT':
      window.localStorage.removeItem('device');
      window.localStorage.removeItem('token');
      window.localStorage.removeItem('isAuthenticated');
      return {
        ...state,
        isAuthenticated: 'false',
        token: 'false',
        device: 'false',
        expiration: 'false',
      };
    case 'LOGIN':
      window.localStorage.setItem(
        'isAuthenticated',
        action.payload.isAuthenticated || 'false'
      );
      window.localStorage.setItem('device', action.payload.device || 'false');
      window.localStorage.setItem('token', action.payload.token || 'false');
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
        token: action.payload.token,
        device: action.payload.device,
      };
    default:
      return state;
  }
};

export const Context = createContext({} as IContext);

export const Provider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  return (
    <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>
  );
};
