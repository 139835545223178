/* eslint-disable @typescript-eslint/no-explicit-any */
export enum HelpersTypes {
  'SHOW_MODAL' = 'SHOW_MODAL',
  'SHOW_LOADER' = 'SHOW_LOADER',
}

export interface HelpersState {
  showModal: boolean;
  showLoader: boolean;
}

export type HelpersAction = {
  type: HelpersTypes;
  payload: any;
};
