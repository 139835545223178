import { DeviceAction, DeviceState } from './Device.types';

const initialState = {
  name: window.localStorage.getItem('device') || '',
  token: window.localStorage.getItem('token') || '',
};

export const deviceReducer = (
  state: DeviceState = initialState,
  action: DeviceAction
): DeviceState => {
  switch (action.type) {
    default:
      return state;
  }
};
