/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { API_URL, MKP_URL } from '../../constants';
import { Event } from './Cart.types';
import { map, sumBy } from 'lodash';
import {
  removeFromCart,
  addClientName,
  resetCart,
} from '../../store/Cart/Cart.actions';
import { resetPreview } from '../../store/Preview/Preview.actions';
import { showModal, showLoader } from '../../store/Helpers/Helpers.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import axios from 'axios';
import dateFormat from 'dateformat';
import useLink from '../../hooks/useLink';

const now = new Date();

const useCart = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cart = useSelector<any, any>((state) => state.cart);
  const helpers = useSelector<any, any>((state) => state.helpers);
  const device = useSelector<any, any>((state) => state.device);
  const currency = useSelector<any, any>((state) => state.currency);
  const total = sumBy(cart.items, 'price');
  const { linkTo } = useLink();

  const handleDelete = (id: string) => {
    dispatch(removeFromCart(id));
  };

  const handleContinueShopping = () => {
    dispatch(resetPreview());
    linkTo('/');
  };

  const handlePlaceOrder = () => {
    dispatch(showModal(true));
  };

  const handleConfirmOrder = async (): Promise<any> => {
    dispatch(showModal(false));
    dispatch(showLoader(true));

    const posOrderData = {
      confirmationCode: cart?.name,
      device: device?.token,
      paid: false,
      orders: map(cart?.items, (item) => ({
        age: item.age,
        code: item.id,
        color: item.color.image,
        customDate: dateFormat(item.customDate, 'yyyy-mm-dd'),
        customFontColor: item.customFontColor,
        ip: item.ip.name,
        layout: item.layout,
        name: item.name,
        option: item.option,
        price: item.price,
        product: item.product.name,
        size: item.size,
        sku: item.sku,
      })),
    };

    try {
      const rq = await axios.post(`${API_URL}/orders`, posOrderData);

      const orderReference = rq?.data?.id;

      const mkpOrderData = {
        documentos: [
          {
            encabezado: {
              bookingNumber: orderReference,
              clave_Cliente: '00001',
              clave_Vendedor: '00001',
              cp_Cliente: '00000',
              descuento: 0,
              fecha_movimiento: dateFormat(now, 'yyyymmdd'),
              hotel: 'NICK',
              moneda: currency?.code,
              nombre_cliente: cart?.name,
              nombre_Vendedor: device?.token,
              oficina_Venta: 'NICKKIOSCO1',
              propina: 0,
              razon_Social_Cliente: 'PUBLICO GENERAL',
              rfc_Cliente: 'XAXX010101000',
              rfc_Empresa: 'STC861023CMA',
              subTotal: total,
              tipo_Cambio: 1,
              tipo_Movimiento: 'I',
              total,
            },
            servicios: map(cart?.items, (item, index) => ({
              cantidad: 1,
              descuento: 0,
              importe_Bruto: item.price,
              importe_Venta: item.price,
              nombre_Servicio_Producto: `${item.product.name} ${item.option} ${item.size}`,
              precio: item.price,
              propina: 0,
              referencia: '',
              servicio_numero: index + 1,
              SKU: item.sku,
            })),
            cobros: [
              {
                folio: orderReference,
                forma_de_Cobro: '99',
                importe: total,
                moneda: currency?.code,
                numeroPago: 1,
                tipo_cambio: 1,
              },
            ],
          },
        ],
      };

      await axios.post(
        `${MKP_URL}/api/v1/transactions/send-order`,
        mkpOrderData
      );
      dispatch(resetPreview());
      dispatch(resetCart());
      dispatch(showLoader(false));
      linkTo(`/t/${orderReference}`);
    } catch (error) {
      console.log(
        '🚀 ~ file: useCart.tsx ~ line 54 ~ handleConfirmOrder ~ error',
        error
      );
    }
  };

  const handleName = (evt: Event) => {
    const name = evt.target?.value || '';
    dispatch(addClientName(name));
  };

  return {
    cart,
    handleConfirmOrder,
    handleContinueShopping,
    handleDelete,
    handleName,
    handlePlaceOrder,
    isLoading: helpers?.showLoader,
    t,
    total,
  };
};

export default useCart;
