import { Provider } from './context';
import { ReactQueryDevtools } from 'react-query/devtools';
import Layout from './layout/GlobalLayout';
import React from 'react';
import Router from './router';

import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

const App: React.FC = (): JSX.Element => (
  <Provider>
    <QueryClientProvider client={queryClient}>
      <Layout>
        <Router />
      </Layout>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </Provider>
);

export default App;
