import { Props } from '../Layout.types';
import { ThemeProvider } from 'styled-components';
import GlobalStyle from '../../styles/globalStyle';
import theme from '../../styles/theme';

const Layout: React.FC<Props> = ({ children }) => (
  <>
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  </>
);

export default Layout;
