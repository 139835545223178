const shadows = {
  one: '0 1px 5px 0 rgba(0, 0, 0, 0.02), 0 1px 4px 0 rgba(0, 0, 0, 0.04)',
  sm: '0px 0.7px 1px rgba(0, 0, 0, 0.25)',
  md: '0px 0.7px 2px 0.4px rgba(0, 0, 0, 0.28)',
  lg: '0px 1px 3px rgba(51, 51, 51, 0.15)',
  xl: '0px 2px 4.82px rgba(0, 0, 0, 0.5)',
};

export type Shadows = typeof shadows;
export default shadows;
