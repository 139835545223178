import { keyframes as styledKeyframes, css } from 'styled-components';

const error = styledKeyframes`
  0%,
  100% {
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    transform: translateX(10px);
  }
  80% {
    transform: translateX(8px);
  }
  90% {
    transform: translateX(-8px);
  }
`;

const rollingFade = styledKeyframes`
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }

  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const fadeIn = styledKeyframes`
  0% {
    opacity: 0;
  }

  15% {
    opacity: 1;
  }
`;

const fadeOut = styledKeyframes`
  85% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`;

const scaleUpBounce = styledKeyframes`
  0% {
    transform: scale(0);
  }

  45% {
    transform: scale(1.2);
  }

  60% {
    transform: scale(0.8);
  }

  75% {
    transform: scale(1.1);
  }

  89% {
    transform: scale(0.9)
  }

  100% {
    transform: scale(1)
  }
`;

const rotateKeyframes = styledKeyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const rotate = css`
  animation: '0.7s' ${rotateKeyframes} linear infinite;
`;

const keyframes = {
  error,
  fadeIn,
  fadeOut,
  rollingFade,
  rotate,
  scaleUpBounce,
};

export type Keyframes = typeof keyframes;

export default keyframes;
