import { composeWithDevTools } from 'redux-devtools-extension';
import { createStore, combineReducers } from 'redux';

import { cartReducer } from './Cart/Cart.reducer';
import { currencyReducer } from './Currency/Currency.reducer';
import { deviceReducer } from './Device/Device.reducer';
import { helpersReducer } from './Helpers/Helpers.reducer';
import { ipReducer } from './Ips/Ips.reducer';
import { previewReducer } from './Preview/Preview.reducer';

export const state = combineReducers({
  cart: cartReducer,
  currency: currencyReducer,
  device: deviceReducer,
  helpers: helpersReducer,
  ip: ipReducer,
  preview: previewReducer,
});

export const store = createStore(state, composeWithDevTools());
