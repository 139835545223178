const fonts = {
  primary: 'Futura, sans-serif',
  AHDN: 'AHDN',
  Amarillo: 'Amarillo',
  BlackStuff: 'BlackStuff',
  Brothers: 'Brothers',
  BurbankBigWideBold: 'BurbankBigWideBold',
  BurbankBigWideBold1: 'BurbankBigWideBold1',
  CCHeroSandwichMeat: 'CCHeroSandwichMeat',
  CooperBlackStd: 'CooperBlackStd',
  CooperBlackStdItalic: 'CooperBlackStdItalic',
  DaddyInSpace: 'DaddyInSpace',
  DKPusekatt: 'DKPusekatt',
  FeastofFleshBB: 'FeastofFleshBB',
  FeastofFleshBBItalic: 'FeastofFleshBBItalic',
  Fido: 'Fido',
  FinkBold: 'FinkBold',
  FrutigerNextLT: 'FrutigerNextLT',
  FuturaBTBold: 'FuturaBTBold',
  FuturaExtraBold: 'FuturaExtraBold',
  FuturaPTBold: 'FuturaPTBold',
  FuturaStdBold: 'FuturaStdBold',
  Garfield: 'Garfield',
  Garfield1: 'Garfield1',
  HouseARamaKingpin: 'HouseARamaKingpin',
  JournalduSoir: 'JournalduSoir',
  KrabbyPatty: 'KrabbyPatty',
  Modak: 'Modak',
  Oduda: 'Oduda',
  Pinkfong: 'Pinkfong',
  PinkfongPlay: 'PinkfongPlay',
  Rocko: 'Rocko',
  Rugrats: 'Rugrats',
  VarsityRegular: 'VarsityRegular',
};

export type Fonts = typeof fonts;
export default fonts;
