/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonStyle } from '../../components/CTAButton/CTAButton.types';
import {
  Container,
  Content,
  Input,
  Img,
  Label,
  AmountContainer,
  Total,
  Amount,
  Small,
} from './Modal.styles';
import { showModal } from '../../store/Helpers/Helpers.actions';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '../../assets/CloseIcon.svg';
import CTAButton from '../../components/CTAButton';
import useCart from '../../pages/Cart/useCart';

const Modal: React.FC = () => {
  const dispatch = useDispatch();
  const { t, cart, total, handleName, handleConfirmOrder } = useCart();
  const helpers = useSelector<any, any>((state) => state.helpers);

  const handleClose = () => {
    dispatch(showModal(false));
  };

  return (
    <Container isVisible={helpers.showModal}>
      <Content>
        <Img alt="Close" loading="lazy" onClick={handleClose} src={CloseIcon} />
        <Label htmlFor="name">{t('Client name or Room number')}</Label>
        <Input
          autoComplete="off"
          id="name"
          onChange={handleName}
          type="text"
          value={cart.name}
        />
        <AmountContainer>
          <Total>{t('Total amount')}</Total>
          <Amount>
            <Small>$</Small>
            {total}
            <Small>MXN</Small>
          </Amount>
        </AmountContainer>
        {cart?.name?.length > 3 && (
          <CTAButton
            buttonStyle={ButtonStyle.primary}
            onClick={handleConfirmOrder}
          >
            {t('Confirm order')}
          </CTAButton>
        )}
      </Content>
    </Container>
  );
};

export default Modal;
