import {
  CartAction,
  CartState,
  CartTypes
} from './Cart.types';
import { v4 as uuid } from 'uuid';

const initialState = {
  id: '',
  items: [],
};

export const cartReducer = (
  state: CartState = initialState,
  action: CartAction
): CartState => {
  switch (action.type) {
    case CartTypes.ADD_CLIENT_NAME: {
      return {
        ...state,
        name: action.payload,
      }
    }
    case CartTypes.ADD_TO_CART: {
      return {
        ...state,
        id: uuid(),
        items: [
            ...state.items,
            action.payload
        ]
      }
    }
    case CartTypes.REMOVE_FROM_CART: {
      const filteredItems = state.items.filter((item) => item.id !== action.payload)
      return {
        ...state,
        items: [
          ...filteredItems
        ]
      }
    }
    case CartTypes.RESET_CART: {
      return {
        ...initialState,
      }
    }
    default:
      return state
  }
};
