/* eslint-disable @typescript-eslint/no-explicit-any */
import { ButtonStyle } from '../../components/CTAButton/CTAButton.types';
import { Container, Logo } from './Navigation.styles';
import { resetPreview } from '../../store/Preview/Preview.actions';
import { useSelector, useDispatch } from 'react-redux';

import CartIcon from '../../assets/CartIcon.svg';
import CTAButton from '../../components/CTAButton';
import HomeIcon from '../../assets/HomeIcon.svg';
import nickelodeonLogo from '../../assets/NickelodeonLogo.svg';
import useLink from '../../hooks/useLink';

const Navigation: React.FC = () => {
  const dispatch = useDispatch();
  const cart = useSelector<any, any>((state) => state.cart);
  const { linkTo } = useLink();

  const handleGoToHome = () => {
    dispatch(resetPreview());
    linkTo('/');
  };

  return (
    <Container>
      <CTAButton
        buttonStyle={ButtonStyle.secondary}
        onClick={handleGoToHome}
        rounded
      >
        <img src={HomeIcon} alt="Home" />
      </CTAButton>
      <Logo
        alt="Navigation Riviera Maya"
        loading="lazy"
        src={nickelodeonLogo}
      />
      <CTAButton
        badge={cart.items.length}
        buttonStyle={ButtonStyle.primary}
        onClick={() => linkTo('/cart')}
        rounded
        showBadge={true}
      >
        <img src={CartIcon} alt="Cart" />
      </CTAButton>
    </Container>
  );
};

export default Navigation;
