import { DefaultTheme } from 'styled-components';

import breakpoints from './breakpoints';
import colors from './colors';
import flexBoxGrid from './grid';
import fonts from './fonts';
import hideScrollbars from './hideScrollbars';
import keyframes from './keyframes';
import optimizeImage from './optimizeImage';
import shadows from './shadows';

const theme: DefaultTheme = {
  breakpoints,
  colors,
  flexBoxGrid,
  fonts,
  hideScrollbars,
  keyframes,
  optimizeImage,
  shadows,
};

export default theme;
