import { Container, Content, Img } from './Footer.styles';
import { Grid } from 'react-styled-flexboxgrid';

import LanguageSelector from '../../components/LanguageSelector';
import WhiteSlime from '../../assets/WhiteSlime.svg';

const Footer: React.FC = () => (
  <Container>
    <Grid>
      <Content>
        <LanguageSelector />
        <Img alt="Slime" id="whiteSlime" loading="lazy" src={WhiteSlime} />
      </Content>
    </Grid>
  </Container>
);

export default Footer;
