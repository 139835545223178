import { HelpersAction, HelpersTypes } from './Helpers.types';

export const showModal = (showModal: boolean): HelpersAction => ({
  type: HelpersTypes.SHOW_MODAL,
  payload: showModal,
});

export const showLoader = (showLoader: boolean): HelpersAction => ({
  type: HelpersTypes.SHOW_LOADER,
  payload: showLoader,
});
