import { HelpersAction, HelpersState, HelpersTypes } from './Helpers.types';

const initialState = {
  showLoader: false,
  showModal: false,
};

export const helpersReducer = (
  state: HelpersState = initialState,
  action: HelpersAction
): HelpersState => {
  switch (action.type) {
    case HelpersTypes.SHOW_MODAL: {
      return {
        ...state,
        showModal: action.payload,
      };
    }
    case HelpersTypes.SHOW_LOADER: {
      return {
        ...state,
        showLoader: action.payload,
      };
    }
    default:
      return state;
  }
};
