import styled from 'styled-components';

const navigationHeight = 150;
const footerHeight = 80;
const safeArea = navigationHeight + footerHeight;

export const Container = styled.div`
  display: grid;
  gap: 0px;
  grid-template-areas:
    'NAVIGATION'
    'CONTENT'
    'FOOTER';
  grid-template-columns: repeat(1, 1fr);
  grid-template-rows: repeat(3, 1fr);
  height: 100vh;
  position: relative;
  width: 100%;
`;

export const MainHeader = styled.section`
  align-items: center;
  display: flex;
  grid-area: NAVIGATION;
  height: ${navigationHeight}px;
`;

export const Content = styled.div`
  grid-area: CONTENT;
  height: calc(100vh - ${safeArea}px);
  overflow-x: auto;
  padding-top: 0.75rem;
  width: 100%;
`;

export const MainFooter = styled.div`
  grid-area: FOOTER;
`;
