import { PreviewAction, PreviewState, PreviewTypes } from './Preview.types';

const initialState = {
  layout: '',
  name: '',
  option: '',
  price: 0,
  size: '',
  sku: '',
  customDate: new Date(),
  customFontColor: '',
};

export const previewReducer = (
  state: PreviewState = initialState,
  action: PreviewAction
): PreviewState => {
  switch (action.type) {
    case PreviewTypes.ADD_NAME: {
      return {
        ...state,
        name: action.payload,
      };
    }
    case PreviewTypes.ADD_IP: {
      return {
        ...state,
        ip: action.payload,
      };
    }
    case PreviewTypes.ADD_PRODUCT: {
      return {
        ...state,
        product: action.payload,
      };
    }
    case PreviewTypes.ADD_SIZE: {
      return {
        ...state,
        size: action.payload,
      };
    }
    case PreviewTypes.ADD_PRICE: {
      return {
        ...state,
        price: action.value || 0,
      };
    }
    case PreviewTypes.ADD_COLOR: {
      return {
        ...state,
        color: action.payload,
      };
    }
    case PreviewTypes.ADD_FONT_COLOR: {
      return {
        ...state,
        customFontColor: action.payload,
      };
    }
    case PreviewTypes.ADD_LAYOUT: {
      return {
        ...state,
        layout: action.payload,
      };
    }
    case PreviewTypes.ADD_AGE: {
      return {
        ...state,
        age: action.value,
      };
    }
    case PreviewTypes.ADD_DATE: {
      return {
        ...state,
        customDate: action.payload,
      };
    }
    case PreviewTypes.ADD_SKU: {
      return {
        ...state,
        sku: action.payload,
      };
    }
    case PreviewTypes.ADD_OPTION: {
      return {
        ...state,
        option: action.payload,
      };
    }
    case PreviewTypes.RESET_PREVIEW: {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
