import { css } from 'styled-components';

const hideScrollbars = css`
  -ms-overflow-style: none; /* IE 10+ */
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    background: transparent; /* Chrome/Safari/Webkit */
    display: none;
    width: 0px;
  }
`;

export type HideScrollBars = typeof hideScrollbars;
export default hideScrollbars;
