import { CurrencyAction, CurrencyState } from './Currency.types';

const initialState = {
  code: 'MXN',
};

export const currencyReducer = (
  state: CurrencyState = initialState,
  action: CurrencyAction
): CurrencyState => {
  switch (action.type) {
    default:
      return state;
  }
};
