import { css } from 'styled-components';

const optimizeImage = css`
  -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
  image-rendering: -moz-crisp-edges; /* Firefox */
  image-rendering: -o-crisp-edges; /* Opera */
  image-rendering: -webkit-optimize-contrast; /* Webkit (non-standard naming) */
  image-rendering: crisp-edges;
`;

export type OptimizeImage = typeof optimizeImage;
export default optimizeImage;
