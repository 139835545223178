const flexBoxGrid = {
  gridSize: 12, // columns
  gutterWidth: 1, // rem
  outerMargin: 2, // rem
  mediaQuery: 'only screen',
  container: {
    sm: 46, // rem
    md: 61, // rem
    lg: 90, // rem
  },
  breakpoints: {
    xs: 0, // em
    sm: 48, // em
    md: 64, // em
    lg: 90, // em
  },
};

export type FlexBoxGrid = typeof flexBoxGrid;
export default flexBoxGrid;
