import { IPAction, IPState, IPTypes } from './Ips.types';

const initialState = {
  id: '',
  image: '',
  layouts: [],
  name: '',
};

export const ipReducer = (
  state: IPState = initialState,
  action: IPAction
): IPState => {
  switch (action.type) {
    case IPTypes.ADD_LAYOUTS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
