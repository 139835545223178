import { Flag } from './LanguageSelector.styles';
import { map } from 'lodash';

import enFlag from '../../assets/en.svg';
import esFlag from '../../assets/es.svg';
import useLanguages from '../../hooks/useLanguages';

const LanguageSelector: React.FC = () => {
  const { languages, changeLanguage } = useLanguages();
  const getFlag = (language: string) => (language === 'en' ? enFlag : esFlag);

  return (
    <>
      {languages &&
        map(languages, (language) => (
          <Flag
            alt={language.code}
            key={language.id}
            onClick={() => changeLanguage(language.code)}
            src={getFlag(language.code)}
          />
        ))}
    </>
  );
};

export default LanguageSelector;
