import styled from 'styled-components';
import { ButtonStyle } from '../CTAButton/CTAButton.types';

export const Button = styled.button<{
  buttonStyle?: ButtonStyle;
  isRounded?: boolean;
  disabled?: boolean;
}>`
  ${({ theme, buttonStyle, disabled }) =>
    !disabled &&
    buttonStyle === ButtonStyle.alternative &&
    ` background: ${theme.colors.pink}`};
  ${({ theme, buttonStyle, disabled }) =>
    !disabled &&
    buttonStyle === ButtonStyle.contrast &&
    ` background: ${theme.colors.purple}`};
  ${({ theme, buttonStyle, disabled }) =>
    !disabled &&
    buttonStyle === ButtonStyle.primary &&
    ` background: ${theme.colors.green}`};
  ${({ theme, buttonStyle, disabled }) =>
    !disabled &&
    buttonStyle === ButtonStyle.secondary &&
    ` background: ${theme.colors.orange}`};
  ${({ theme, disabled }) =>
    disabled && ` background: ${theme.colors.lightGray}`};
  border-radius: ${({ isRounded }): string => (isRounded ? '50px' : '8px')};
  box-shadow: ${({ theme }): string => theme.shadows.md};
  color: ${({ theme }): string => theme.colors.white};
  font-size: 1.25em;
  height: 50px;
  position: relative;
  text-shadow: ${({ theme }): string => theme.shadows.xl};
  text-transform: uppercase;
  width: ${({ isRounded }): string => (isRounded ? '50px' : '100%')};

  span {
    align-items: center;
    background: ${({ theme }): string => theme.colors.orange};
    border-radius: 50px;
    box-shadow: ${({ theme }): string => theme.shadows.md};
    display: flex;
    font-size: 0.6em;
    height: 25px;
    justify-content: center;
    line-height: 1rem;
    position: absolute;
    right: -5px;
    top: -5px;
    width: 25px;
  }

  & img {
    height: 25px;
    margin-top: -5px;
  }

  &:active {
    background: ${({ theme }): string => theme.colors.purple};
  }
`;
