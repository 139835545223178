import { API_URL } from '../constants';
import { Context } from '../context';
import { logoutAction } from '../context/context.actions';
import { useContext, useEffect } from 'react';

import useRQ from './useRQ';

const useDeviceStatus = (): void => {
  const {
    state: { token, device },
    dispatch,
  } = useContext(Context);

  const { isLoading, isError, data } = useRQ(
    `device${token}`,
    `${API_URL}/devices/${token}`
  );

  const checkStatus = (): boolean => {
    const now = new Date().toISOString();
    const isActive =
      data?.active && data?.code === device && data?.expiration > now;

    if (!isActive || isError) {
      dispatch(logoutAction());
    }

    return isActive;
  };

  useEffect(() => {
    if (!isLoading) {
      checkStatus();
      console.log(
        'Device Status:',
        checkStatus() ? 'Active 🚀' : 'Inactive 🤖'
      );
    }
  });
};

export default useDeviceStatus;
