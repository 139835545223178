import styled from 'styled-components';

export const Container = styled.div<{ internalLoader?: boolean }>`
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  left: 0;
  margin: auto;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 2;
  background: ${({ internalLoader }): string =>
    internalLoader ? 'none' : 'rgba(0, 0, 0, 0.85)'};
`;
