import { CartAction, CartTypes, CartItem } from './Cart.types';

export const addToCart = (item: CartItem): CartAction => ({
  type: CartTypes.ADD_TO_CART,
  payload: item,
});

export const removeFromCart = (id: string): CartAction => ({
  type: CartTypes.REMOVE_FROM_CART,
  payload: id,
});

export const addClientName = (name: string): CartAction => ({
  type: CartTypes.ADD_CLIENT_NAME,
  payload: name,
});

export const resetCart = (): CartAction => ({
  type: CartTypes.RESET_CART,
  payload: '',
});
