/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useHistory } from 'react-router-dom';

const useLink = () => {
  const history = useHistory();

  const linkTo = (route: string) => {
    history.push(route);
  };

  return { linkTo };
};

export default useLink;
