import { API_URL } from '../../constants';
import { changeLanguageAction } from '../../context/context.actions';
import { Context } from '../../context';
import { IReturnData } from './useLanguages.types';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import useRQ from '../useRQ';

const useLanguages = (): IReturnData => {
  const { i18n } = useTranslation();
  const { dispatch } = useContext(Context);
  const { data: languages } = useRQ(`languages`, `${API_URL}/languages`);

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    dispatch(changeLanguageAction(language));
  };

  return { languages, changeLanguage };
};

export default useLanguages;
