export enum ButtonStyle {
  'primary' = 'primary',
  'secondary' = 'secondary',
  'alternative' = 'alternative',
  'contrast' = 'contrast',
}

export interface CTAButtonProps {
  children: JSX.Element;
  onClick?: () => void;
  buttonStyle?: ButtonStyle;
  rounded?: boolean;
  badge?: number;
  showBadge?: boolean;
  isDisabled?: boolean;
}
