import { Color, Detail, PreviewAction, PreviewTypes } from './Preview.types';

export const addName = (name: string): PreviewAction => ({
  type: PreviewTypes.ADD_NAME,
  payload: name,
});

export const addIP = (ip: Detail): PreviewAction => ({
  type: PreviewTypes.ADD_IP,
  payload: ip,
});

export const addProduct = (product: Detail): PreviewAction => ({
  type: PreviewTypes.ADD_PRODUCT,
  payload: product,
});

export const addSize = (size: string): PreviewAction => ({
  type: PreviewTypes.ADD_SIZE,
  payload: size,
});

export const addPrice = (price: number): PreviewAction => ({
  type: PreviewTypes.ADD_PRICE,
  payload: '',
  value: price || 0,
});

export const addColor = (color: Color): PreviewAction => ({
  type: PreviewTypes.ADD_COLOR,
  payload: color,
});

export const addLayout = (layout: string): PreviewAction => ({
  type: PreviewTypes.ADD_LAYOUT,
  payload: layout,
});

export const addAge = (age: number): PreviewAction => ({
  type: PreviewTypes.ADD_AGE,
  payload: '',
  value: age || 0,
});

export const addDate = (date: Date): PreviewAction => ({
  type: PreviewTypes.ADD_DATE,
  payload: date || new Date(),
});

export const addCustomFontColor = (color: string): PreviewAction => ({
  type: PreviewTypes.ADD_FONT_COLOR,
  payload: color,
});

export const addSKU = (sku: string): PreviewAction => ({
  type: PreviewTypes.ADD_SKU,
  payload: sku,
});

export const addOption = (option: string): PreviewAction => ({
  type: PreviewTypes.ADD_OPTION,
  payload: option,
});

export const resetPreview = (): PreviewAction => ({
  type: PreviewTypes.RESET_PREVIEW,
  payload: '',
});
