import { Button } from './CTAButton.styles';
import { CTAButtonProps } from './CTAButton.types';

const CTAButton: React.FC<CTAButtonProps> = ({
  children,
  buttonStyle,
  rounded,
  badge,
  showBadge,
  onClick,
  isDisabled = false,
}) => {
  const renderBadge = showBadge && <span>{badge}</span>;

  return (
    <Button
      disabled={isDisabled}
      buttonStyle={buttonStyle}
      isRounded={rounded}
      onClick={onClick}
    >
      {children}
      {renderBadge}
    </Button>
  );
};

export default CTAButton;
