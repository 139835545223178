import { Context } from '../context';
import { map } from 'lodash';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import { useContext } from 'react';

import Loader from '../components/Loader';
import PrivateRoute from '../components/PrivateRoute';

const Cart = lazy(() => import('../pages/Cart'));
const Home = lazy(() => import('../pages/Home'));
const Ips = lazy(() => import('../pages/Ips'));
const Logout = lazy(() => import('../pages/Logout'));
const Preview = lazy(() => import('../pages/Preview'));
const Products = lazy(() => import('../pages/Products'));
const Thanks = lazy(() => import('../pages/Thanks'));

const Router: React.FC = () => {
  const {
    state: { isAuthenticated },
  } = useContext(Context);
  const location = useLocation();
  const isAuth = isAuthenticated !== 'false';

  const routes = [
    {
      Component: isAuth ? Ips : Home,
      name: 'Home',
      path: '/',
      isPrivate: isAuth ? true : false,
    },
    {
      Component: Logout,
      name: 'Logout',
      path: '/logout',
      isPrivate: true,
    },
    {
      Component: Cart,
      name: 'Cart',
      path: '/cart',
      isPrivate: true,
    },
    {
      Component: Products,
      name: 'Products',
      path: '/p/:id',
      isPrivate: true,
    },
    {
      Component: Preview,
      name: 'Preview',
      path: '/p/:ip/:product',
      isPrivate: true,
    },
    {
      Component: Thanks,
      name: 'Thanks',
      path: '/t/:id',
      isPrivate: true,
    },
  ];

  return (
    <Suspense fallback={<Loader />}>
      <Switch location={location} key={location.pathname}>
        {map(routes, (route, index) => (
          <Route key={index} exact path={route.path}>
            {route.isPrivate ? (
              <PrivateRoute isAuthenticated={isAuth}>
                <route.Component />
              </PrivateRoute>
            ) : (
              <route.Component />
            )}
          </Route>
        ))}
      </Switch>
    </Suspense>
  );
};

export default Router;
