/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useQuery } from 'react-query';

import axios from 'axios';

const useRQ = (key: string, url: string) => {
  const fetchData = async () => {
    try {
      const result = await axios.get(`${url}`);
      return result.data;
    } catch (error) {
      return error;
    }
  };

  const options = {
    staleTime: 5000,
    refetchInterval: 5000,
  };

  const { isLoading, isError, data } = useQuery(key, fetchData, options);

  return { isLoading, isError, data };
};

export default useRQ;
